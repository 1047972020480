import React from "react"
import styles from "./styles.module.less"

import arrow from "./img/arrowWh.svg"

const Intro = props => {
  return (
    <div className={styles.Intro}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>
            We made it! <br />
            (with you)
          </h1>
        </div>
        <div className={styles.paragraph}>
          <p>
            As your digital partner, we're honored to help you{" "}
            <br className={styles.mobileHide} />
            spread the word about your brand this year{" "}
            <br className={styles.mobileHide} />
            (without spreading any of the 2020 mayhem).
            <br />
            <br />
            In that spirit, we stuck to pixels for this year's{" "}
            <br className={styles.mobileHide} />
            holiday card——you know, something you don't have to{" "}
            <br className={styles.mobileHide} />
            wipe down first. Just scroll and enjoy.
          </p>
        </div>

        {/* <button className={styles.button} onClick="">
          <img src={arrow} alt="" />
        </button> */}
      </div>
    </div>
  )
}

export default Intro
