import React from "react"

import OCSP from "components/Holiday2020/OCSP"
import Layout from "/components/Holiday2020/components/Layout"
import SEO from "/components/seo"

const OCSPHoliday2020 = () => (
  <>
    <SEO
      title="Thesis | Holiday 2020: OTSN"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />

    <OCSP />
  </>
)

export default OCSPHoliday2020
