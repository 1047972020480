import React from "react"
import styles from "./styles.module.less"

import img from "./img/placeholder.jpg"

const ImgCrop = props => {
  return (
    <div className={styles.ImgCrop}>
      <div className={styles.imgContain}>
        <div className={styles.numbers}>
          <p>20</p>
          <p>20</p>
          <p>20</p>
          <p>20</p>
        </div>
        <div className={styles.img}>
          <img src={props.img} alt="" />
        </div>
      </div>

      <div className={styles.par}>
        <h1>{props.header}</h1>
        <p>{props.par}</p>
      </div>
    </div>
  )
}

export default ImgCrop
